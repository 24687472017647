import React from "react";
import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
  Badge,
} from "@chakra-ui/react";

export default function NewsItem({ type, title, image, date }) {
  function badgeColor(type) {
    let color;
    switch (type) {
      case "info":
        color = "green";
        break;
      case "event":
        color = "orange";
        break;
      case "update":
        color = "blue";
        break;
      default:
        color = "grey";
    }
    return color;
  }

  return (
    <Center py={12}>
      <Box
        role={"group"}
        p={6}
        maxW={"330px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
      >
        <Box
          rounded={"lg"}
          mt={-12}
          pos={"relative"}
          height={"200px"}
          _after={{
            transition: "all .3s ease",
            content: '""',
            w: "full",
            h: "full",
            pos: "absolute",
            top: 5,
            left: 0,
            backgroundImage: `url(${image})`,
            filter: "blur(15px)",
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: "blur(20px)",
            },
          }}
        >
          <Image
            alt={"emilchronicleonline ECO-IRIS"}
            height={210}
            width={282}
            objectFit={"cover"}
            loading={"lazy"}
            src={image}
          />
        </Box>
        <Stack pt={10} align={"left"}>
          <Stack direction={"row"} align={"center"}>
            <Badge
              w={"auto"}
              textTransform={"lowercase"}
              colorScheme={badgeColor(type)}
            >
              {type}
            </Badge>
          </Stack>
          <Heading
            fontSize={"md"}
            fontFamily={"body"}
            fontWeight={500}
            textOverflow="ellipsis"
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            overflowWrap={"break-word"}
            textAlign={"start"}
          >
            {title}
          </Heading>
          <Stack direction={"row"} align={"center"}>
            <Text fontSize={"sm"} color={"gray.600"}>
              {date}
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Center>
  );
}
