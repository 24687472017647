import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

function badgeColor(type) {
  let color;
  switch (type) {
    case "info":
      color = "green";
      break;
    case "event":
      color = "orange";
      break;
    case "update":
      color = "blue";
      break;
    default:
      color = "grey";
  }
  return color;
}

const HighlightItem = ({ image, title, type, date }) => {
  return (
    <>
      <Box overflow="hidden" align={"center"}>
        <Image
          alt={"emil chronicle online ECO-IRIS"}
          src={image}
          overflow="hidden"
          maxW={250}
          h={250}
          loading={"lazy"}
          objectFit="cover"
          transition="transform 0.5s ease-in-out"
          _hover={{
            transform: "scale(1.1) rotate(-3deg)",
          }}
        />
      </Box>
      <Box paddingTop={5} maxW={350}>
        <Badge
          w={"auto"}
          textTransform={"lowercase"}
          colorScheme={badgeColor(type)}
        >
          {type}
        </Badge>
        <Heading
          fontSize={"md"}
          maxW={300}
          color="rgb(205,207,248)"
          textAlign={"left"}
          textOverflow="ellipsis"
          overflow={"hidden"}
          whiteSpace={"nowrap"}
          overflowWrap={"break-word"}
        >
          {title}
        </Heading>
      </Box>
      <Stack direction={"row"} align={"center"}>
        <Text fontSize={"sm"} color={"gray.300"}>
          {date}
        </Text>
      </Stack>
    </>
  );
};

function Highlight() {
  const [newsData, setNewsData] = useState([]);
  const [highlight, setHighlight] = useState([]);
  const [ktRank, setKTRank] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    axios
      .get(`/content/items/news?sort=%7Bdate%3A+-1%7D&limit=6`)
      .then((response) => {
        setNewsData(response.data);
      });

    axios.get(`/content/items/highlight`).then((response) => {
      setHighlight(response.data);
    });

    axios
      .get(`https://member.eco-iris.com/php/ktrank.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response);
        setKTRank(response);
      });
  }, []);

  return (
    <Flex
      bg="rgba(25,28,52,1)"
      flexDirection={"column"}
      alignItems="center"
      zIndex={999}
      paddingTop={100}
      paddingBottom={100}
    >
      <Heading
        size={"2xl"}
        color="rgb(205,207,248)"
        fontStyle={"inherit"}
        zIndex={999}
        mb={50}
      >
        WHAT&apos;S NEW
      </Heading>
      <Flex maxW={1200}>
        {highlight.length > 0 ? (
          <Carousel
            autoFocus
            autoPlay
            infiniteLoop
            selectedItem={index}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            interval={5000}
            preventMovementUntilSwipeScrollTolerance={false}
            onChange={(index) => setIndex(index)}
          >
            {highlight.map((e) => {
              const imagePath = `https://api.eco-iris.com/storage/uploads/${e.image.path}`;
              return <Image src={imagePath} alt={e.alt} key={e._id} />;
            })}
          </Carousel>
        ) : null}
      </Flex>
      {newsData.length > 0 ? (
        <Flex
          direction={"column"}
          justify={"center"}
          align={"center"}
          // bg="rgba(55,58,82,0.5)"
          w={"full"}
          p={50}
        >
          <Wrap
            spacing={2}
            zIndex={999}
            marginTop={50}
            maxW={1300}
            justify={"center"}
          >
            {newsData.map((data) => {
              const link = `/news/${data._id}`;
              return (
                <WrapItem
                  key={data._id}
                  maxW="sm"
                  overflow="hidden"
                  p={2}
                  flexDirection="column"
                >
                  <Link
                    href={link}
                    onClick={(e) =>
                      data.content === "" ? e.preventDefault() : {}
                    }
                  >
                    <HighlightItem
                      title={data.title}
                      image={`https://api.eco-iris.com/storage/uploads/${data.image.path}`}
                      date={data.date}
                      type={data.type}
                    />
                  </Link>
                </WrapItem>
              );
            })}
          </Wrap>
          <Link href="/news" style={{ textDecoration: "none" }}>
            <Button marginTop={10} size="md">
              VIEW ALL
            </Button>
          </Link>
          <Box>
            <Heading
              size={"xl"}
              color="rgb(205,207,248)"
              fontStyle={"inherit"}
              zIndex={999}
              mt={50}
            >
              KT RANKING
            </Heading>
            {ktRank.map((data) => (
              <Heading key={data.id}>Hello</Heading>
            ))}
          </Box>
        </Flex>
      ) : null}
    </Flex>
  );
}

export default Highlight;
