import { SearchIcon } from "@chakra-ui/icons";
import {
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Spinner,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Loading from "../components/Loading";
import TicketItem from "../components/TicketItem";

export default function Ticket() {
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [ticketData, setTicketData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://api2.eco-iris.com/api/collections/get/ticket?sort[category]=-1&filter[published]=true`,
        {
          headers: {
            "Cockpit-Token": "fc34d8a3902d1758b36786efab00fa",
          },
        }
      )
      .then((response) => {
        setTicketData(response.data.entries);
        setFilteredData(response.data.entries);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (text) {
      setIsSearching(true);
      intervalRef.current = setTimeout(() => {
        const filtered = ticketData.filter((ticket) => {
          const textLowCase = text.toLowerCase();
          const titleLowCase = ticket.title.toLowerCase();
          const categoryLowCase = ticket.category
            ? ticket.category.toLowerCase()
            : "";

          const childItem = ticket.items.filter((item) => {
            const title = item.value.title.toLowerCase();
            return title.includes(textLowCase);
          });

          const deepChildItem = ticket.items.filter((item) => {
            const filterChild = item.value.child?.filter((x) => {
              const childTitle = x.value.title.toLowerCase();
              return childTitle.includes(textLowCase);
            });
            return filterChild?.length > 0;
          });

          return (
            // categoryLowCase.includes(textLowCase) ||
            titleLowCase.includes(textLowCase) ||
            childItem.length > 0 ||
            deepChildItem.length > 0
          );
        });
        setIsSearching(false);
        setFilteredData(filtered);
      }, 1000);
    } else {
      setFilteredData(ticketData);
      clearTimeout(intervalRef.current);
    }

    return () => clearTimeout(intervalRef.current);
  }, [text]);

  return (
    <Wrap
      w={"full"}
      align={"center"}
      marginTop={50}
      direction={"column"}
      minH="calc(100vh - 400px)"
    >
      <Heading>TICKET</Heading>
      <Container>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            // eslint-disable-next-line react/no-children-prop
            children={<SearchIcon color="gray.300" />}
          />
          <InputRightElement
            pointerEvents="none"
            // eslint-disable-next-line react/no-children-prop
            children={isSearching ? <Spinner /> : null}
          />
          <Input
            type="text"
            placeholder="Ticket Name, Item Name"
            onChange={(event) => setText(event.target.value)}
          />
        </InputGroup>
      </Container>
      {isLoading ? <Loading /> : null}
      {filteredData.length > 0 ? (
        <>
          <Wrap
            templateColumns="repeat(3, 3fr)"
            zIndex={999}
            justify={"center"}
          >
            {filteredData.map((data) => {
              const link = `/ticket/${data._id}`;
              return (
                <WrapItem key={data._id}>
                  <Link href={link} style={{ textDecoration: "none" }}>
                    <TicketItem
                      key={data._id}
                      title={data.title}
                      image={`https://api2.eco-iris.com/images/${data.image.path}`}
                      date={data.date}
                      type={data.type}
                    />
                  </Link>
                </WrapItem>
              );
            })}
          </Wrap>
        </>
      ) : !isLoading ? (
        <Heading color={"grey"}>Ticket is not found.</Heading>
      ) : null}
    </Wrap>
  );
}
