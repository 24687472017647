import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
  Wrap,
} from "@chakra-ui/react";
import axios from "axios";
import parse, { attributesToProps } from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "../components/Loading";

function badgeColor(type) {
  let color;
  switch (type) {
    case "info":
      color = "green";
      break;
    case "event":
      color = "orange";
      break;
    case "update":
      color = "blue";
      break;
    default:
      color = "grey";
  }
  return color;
}

function NewsDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [newsData, setNewsData] = useState({});
  const { newsId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/content/item/news/${newsId}`).then((response) => {
      setNewsData(response.data);
      setIsLoading(false);
    });
  }, []);

  const { title, image, type, date, content } = newsData;

  const ContentImg = styled(Image)``;

  const options = {
    replace: (node) => {
      if (node.attribs && node.name === "img") {
        const props = attributesToProps(node.attribs);
        if (node.name === "img") {
          const { src, alt } = props;
          return (
            <Flex align={"center"} justify={"center"}>
              <ContentImg src={`https://api.eco-iris.com/${src}`} alt={alt} />
            </Flex>
          );
        }
      }
    },
  };

  return (
    <Wrap
      w={"full"}
      align={"center"}
      marginTop={50}
      direction={"column"}
      minH="calc(100vh - 400px)"
      p={10}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          role={"group"}
          p={6}
          maxW={"1230px"}
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          boxShadow={"2xl"}
          rounded={"lg"}
          pos={"relative"}
          zIndex={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            pos={"relative"}
            _after={{
              transition: "all .3s ease",
              content: '""',
              w: "full",
              h: "full",
              pos: "absolute",
              top: 5,
              left: 0,
              backgroundImage: `url(${`https://api.eco-iris.com/storage/uploads/${image?.path}`})`,
              backgroundSize: "cover",
              filter: "blur(15px)",
              zIndex: -1,
              filter: "blur(20px)",
            }}
          >
            <Flex justify={"center"}>
              <Image
                alt={"emilchronicleonline ECO-IRIS"}
                w={"auto"}
                h={350}
                loading={"lazy"}
                objectFit={"cover"}
                src={`https://api.eco-iris.com/storage/uploads/${image?.path}`}
              />
            </Flex>
          </Box>
          <Flex mt={50} align={"center"}>
            <Heading
              size={"lg"}
              mr={1}
              display={"flex"}
              textAlign={"center"}
              textTransform={"uppercase"}
              color={badgeColor(type)}
              borderRadius={5}
            >
              {type}:
            </Heading>
            <Heading size={"lg"} alignSelf={"center"}>
              {title}
            </Heading>
          </Flex>
          <Text color={"grey"} align={"start"}>{`Published on ${moment(
            date
          ).format("ddd DD MMM yyyy")}`}</Text>
          <hr />
          <Flex
            pt={50}
            pb={100}
            w={"full"}
            direction="column"
            className="content"
            align={"center"}
            justify={"center"}
          >
            <Flex direction="column" w={"full"}>
              {content ? parse(content, options) : null}
            </Flex>
          </Flex>
        </Box>
      )}
    </Wrap>
  );
}

export default NewsDetail;
