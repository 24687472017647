import React from "react";
import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";
import loading from "../resources/125702-404-page-not-found.json";
import Lottie from "lottie-react";

export default function NotFound() {
  return (
    <Flex
      w={"full"}
      align={"center"}
      marginTop={50}
      direction={"column"}
      minH="calc(100vh - 400px)"
      p={10}
    >
      <Box textAlign="center" py={10} px={6}>
        <Box maxW={500}>
          <Lottie animationData={loading} />
        </Box>
        <Text fontSize="18px" mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color="gray.500" mb={6}>
          The page youre looking for does not seem to exist
        </Text>
      </Box>
    </Flex>
  );
}
