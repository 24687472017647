import React from "react";
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
  Heading,
} from "@chakra-ui/react";
import { FaDiscord, FaFacebookF } from "react-icons/fa";
import LOGO from "../resources/logo_white.png";

function SocialButton({ children, label, href }) {
  return (
    <Button
      bg={useColorModeValue("blackAlpha.1000", "whiteAlpha.1000")}
      rounded="full"
      minW={"80px"}
      minH={"80px"}
      cursor="pointer"
      as="a"
      href={href}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="transform: scale(1.1)"
      _hover={{
        bg: useColorModeValue("blackAlpha.2000", "whiteAlpha.2000"),
        transform: "scale(1.2)",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Button>
  );
}

function Footer() {
  return (
    <Box
      bg="rgba(13,13,22,1)"
      color={useColorModeValue("gray.700", "gray.200")}
      zIndex={999}
    >
      <Container
        as={Stack}
        maxW="6xl"
        py={4}
        spacing={4}
        justify="center"
        align="center"
      >
        <Image
          alt={"emilchronicleonline ECO-IRIS Logo"}
          maxH={100}
          src={LOGO}
          loading={"lazy"}
        />
        <Heading size={"md"} fontWeight={900} color="white">
          OUR COMMUNITY
        </Heading>
        <Stack direction="row" spacing={6}>
          <SocialButton
            label="Facebook"
            href="https://www.facebook.com/ecoirispage"
            size="lg"
          >
            <FaFacebookF color="white" size={"50px"} />
          </SocialButton>
          <SocialButton label="Discord" href="https://discord.gg/kwGcNTvaQG">
            <FaDiscord color="white" size={"50px"} />
          </SocialButton>
        </Stack>
        <Heading size={"md"} fontWeight={500} color="white">
          contact us
        </Heading>
        <Heading size={"sm"} fontWeight={300} lineHeight={0} color="white">
          email: support@eco-iris.com (Support)
        </Heading>
        <Heading size={"sm"} fontWeight={300} lineHeight={1} color="white">
          email: gm.ecoiris@gmail.com (Business)
        </Heading>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle="solid"
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Container
          as={Stack}
          maxW="6xl"
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text color="white">
            © 2022 ECO-IRIS Emil Chronicle Online. All rights reserved
          </Text>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
