import React from "react";
import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
  Badge,
  Flex,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import premium from "../resources/home/21192-premium-gold.json";

export default function TicketDeItem({ title, image, randomItems, ur }) {
  if (randomItems.length > 0) {
    console.log("randomItems ", randomItems);
    return (
      <Flex pt={5} bg="lightgrey" rounded={"lg"} direction={"column"} mb={5}>
        <Heading
          fontSize={"md"}
          fontFamily={"body"}
          fontWeight={500}
          textAlign={"center"}
          textTransform={"uppercase"}
          textShadow={"#FFF 1px 0 3px"}
        >
          {title}
        </Heading>
        <Flex p={5}>
          {randomItems.map((data) => {
            const imageUrl = `https://api2.eco-iris.com/images/${data.value.image.path}`;
            return (
              <Flex
                key={data.value.title}
                role={"group"}
                w={"full"}
                rounded={"lg"}
                pos={"relative"}
                zIndex={1}
                direction={"column"}
                justify={"center"}
              >
                <Image
                  alt={"emilchronicleonline ECO-IRIS Ticket"}
                  rounded={"lg"}
                  height={"auto"}
                  maxW={300}
                  objectFit={"cover"}
                  src={imageUrl}
                  loading={"lazy"}
                />
                <Heading
                  fontSize={"md"}
                  fontFamily={"body"}
                  fontWeight={500}
                  textAlign={"center"}
                  textTransform={"uppercase"}
                  textShadow={"#FFF 1px 0 3px"}
                >
                  {data.value.title}
                </Heading>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    );
  }

  return (
    <Center>
      <Flex
        role={"group"}
        p={6}
        maxW={"330px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
        align={"center"}
        direction={"column"}
      >
        {!ur ? (
          <Image
            alt={"emilchronicleonline ECO-IRIS Ticket"}
            rounded={"lg"}
            w={300}
            objectFit={"cover"}
            src={image}
            loading={"lazy"}
          />
        ) : (
          <Box p={2} bg={"orange"}>
            <Box w={"90px"} pos={"absolute"} mt={"-45"} ml={"-49"}>
              <Lottie animationData={premium} />
            </Box>
            <Image
              alt={"emilchronicleonline ECO-IRIS Ticket"}
              rounded={"lg"}
              w={300}
              objectFit={"cover"}
              src={image}
              loading={"lazy"}
            />
          </Box>
        )}

        <Heading
          fontSize={"md"}
          fontFamily={"body"}
          fontWeight={500}
          textAlign={"center"}
          textShadow={"#FFF 1px 0 3px"}
        >
          {title}
        </Heading>
      </Flex>
    </Center>
  );
}
