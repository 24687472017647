import { Heading, Link, Wrap, WrapItem } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GuideItem from "../components/GuideItem";
import Loading from "../components/Loading";
import TicketDetailItem from "../components/TicketDetailItem";
import TicketItem from "../components/TicketItem";

export default function TicketDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [ticketData, setTicketData] = useState({});
  const { ticketId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://api2.eco-iris.com/api/collections/get/ticket/?filter[_id]=${ticketId}`,
        {
          headers: {
            "Cockpit-Token": "fc34d8a3902d1758b36786efab00fa",
          },
        }
      )
      .then((response) => {
        setTicketData(response.data.entries[0]);
        console.log(response.data.entries[0]);
        setIsLoading(false);
      });
  }, []);
  const { items } = ticketData;

  return (
    <Wrap
      w={"full"}
      align={"center"}
      marginTop={50}
      direction={"column"}
      minH="calc(100vh - 400px)"
    >
      <Heading>{ticketData.title}</Heading>

      {isLoading ? <Loading /> : null}
      <Wrap templateColumns="repeat(3, 3fr)" zIndex={999} justify={"center"}>
        {ticketData.items?.map((data) => {
          const link = `/ticket/${data._id}`;
          return (
            <WrapItem key={data._id}>
              <TicketDetailItem
                key={data._id}
                title={data.value.title}
                image={`https://api2.eco-iris.com/images/${data.value.image.path}`}
                randomItems={data.value.child ? data.value.child : []}
                ur={data.value.ur}
              />
            </WrapItem>
          );
        })}
      </Wrap>
    </Wrap>
  );
}
