import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Square,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
  Link,
  Grid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import * as Router from "react-router-dom";
import Slider from "react-slick";
import { StarIcon } from "@chakra-ui/icons";
import { MdOutlineFileDownload } from "react-icons/md";
import BGHeader from "../resources/bgHeader.png";
import IrisNPC from "../resources/LokiAlma.png";
import head from "../resources/head2.jpg";
import Loading from "../components/Loading";
import axios from "axios";
import { isEditable } from "@testing-library/user-event/dist/utils";

function Download() {
  const [file, setFile] = useState([]);

  useEffect(() => {
    axios.get(`/content/item/download`).then((response) => {
      setFile(response.data);
    });
  }, []);

  const CustomButton = ({ children, icon, disabled }) => (
    <Button
      color={"rgba(255,255,255,1)"}
      bg={"rgba(25,28,52,1)"}
      border={"1px"}
      borderColor={"rgba(255,255,255,1)"}
      size="lg"
      colorScheme="green"
      variant={"outline"}
      _hover={{ bg: "rgba(25,28,52,.7)" }}
      _active={{ bg: "rgba(25,28,52,.7)" }}
      leftIcon={icon}
      p={50}
      disabled={disabled}
    >
      {children}
    </Button>
  );

  return (
    <Flex
      flexDirection={"column"}
      alignItems="center"
      minH="calc(100vh - 400px)"
      // bg={"linear-gradient(to top, #83a4d4, #b6fbff)"}
      brightness="30%"
      backgroundImage={head}
      backgroundSize={"cover"}
      backdropBlur={"10px"}
      justify={"center"}
    >
      {/* <Image src={IrisNPC} pos={"absolute"} right={0} /> */}
      <Flex
        bg={"rgba(255,255,255,.8)"}
        zIndex={999}
        borderRadius={30}
        p={20}
        m={20}
        justify={"center"}
        textAlign={"center"}
        flexDirection={"column"}
      >
        <Heading
          size={"2xl"}
          color={"rgba(13,13,22,1)"}
          fontStyle={"inherit"}
          zIndex={999}
        >
          DOWNLOAD
        </Heading>
        <Wrap
          templateColumns="repeat(3, 3fr)"
          spacing={20}
          zIndex={999}
          marginTop={50}
          justify={"center"}
          align={"center"}
        >
          <Link href={file?.client} style={{ textDecoration: "none" }}>
            <CustomButton
              icon={<MdOutlineFileDownload />}
              disabled={!file.client}
            >
              <Heading size={"xl"} color="#f9f9f9" fontStyle={"inherit"}>
                Full Client
              </Heading>
            </CustomButton>
          </Link>

          <Link href={file?.patch} style={{ textDecoration: "none" }}>
            <CustomButton
              icon={<MdOutlineFileDownload />}
              disabled={!file.patch}
            >
              <Heading size={"xl"} color="#f9f9f9" fontStyle={"inherit"}>
                Patch File
              </Heading>
            </CustomButton>
          </Link>
        </Wrap>
        <Wrap
          templateColumns="repeat(3, 3fr)"
          spacing={2}
          zIndex={999}
          marginTop={50}
          justify={"center"}
          align={"center"}
          direction={"column"}
        >
          <Heading color={"rgba(13,13,22,1)"}>วิธีติดตั้งเกม</Heading>
          <Box textAlign={"left"} color={"rgba(13,13,22,1)"}>
            <Text>
              1. ดาวน์โหลด Full Client แล้วย้ายไฟล์ ECO-IRIS.zip
              ไปยังโฟร์เดอร์ที่ต้องการติดตั้ง
            </Text>
            <Text>2. ทำการแตกไฟล์</Text>
            <Text>3. ดาวน์โหลด Patch File เพื่ออัพเดทข้อมูลเกม</Text>
            <Text>4. แตกไฟล์ Patch file ทับลงไปในโฟร์เดอร์ที่ติดตั้งเกม</Text>
            <Text>5. เข้าเกมผ่านไฟล์ eco.exe</Text>
          </Box>
        </Wrap>
      </Flex>
    </Flex>
  );
}

export default Download;
