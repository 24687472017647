import React from "react";
import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
  Badge,
} from "@chakra-ui/react";

export default function TicketItem({ type, title, image, date }) {
  return (
    <Center>
      <Box
        role={"group"}
        p={6}
        maxW={"330px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        // boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
      >
        <Image
          alt={"emilchronicleonline ECO-IRIS"}
          rounded={"lg"}
          height={200}
          width={282}
          objectFit={"cover"}
          src={image}
          loading={"lazy"}
        />
        <Heading
          fontSize={"md"}
          fontFamily={"body"}
          fontWeight={500}
          textAlign={"center"}
          textShadow={"#FFF 1px 0 3px"}
        >
          {title}
        </Heading>
      </Box>
    </Center>
  );
}
