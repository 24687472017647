import React from "react";
import Lottie from "lottie-react";
import loading from "../resources/home/93254-dark-blue-loading.json";
import { Box } from "@chakra-ui/react";

function Loading() {
  return (
    <Box maxW={500}>
      <Lottie animationData={loading} />
    </Box>
  );
}

export default Loading;
