import {
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React from "react";
import { FaDiscord, FaFacebook } from "react-icons/fa";
import LOGO from "../resources/logo_white.png";
import "../css/animation.css";

function Opening() {
  const CustomButton = ({ children, icon }) => (
    <Button
      color={"rgba(255,255,255,1)"}
      border={"1px"}
      borderColor={"rgba(255,255,255,1)"}
      m={5}
      size="lg"
      colorScheme="green"
      variant={"outline"}
      _hover={{ bg: "rgba(0,0,0,.5)" }}
      _active={{ bg: "rgba(0,0,0,1)" }}
      leftIcon={icon}
    >
      {children}
    </Button>
  );

  return (
    <Flex
      bg={"radial-gradient(rgba(25,28,52,1), rgba(25,28,52,.9))"}
      minH="calc(100vh)"
      justify={"center"}
      align={"center"}
      direction={"column"}
    >
      <Container align={"center"}>
        <Image
          alt={"emilchronicleonline ECO-IRIS logo"}
          src={LOGO}
          loading={"lazy"}
        />
      </Container>
      <Heading color={"#ffffff"} m={50}>
        IS COMING..
      </Heading>
      ,
      <Wrap flexDirection={"row"} justify="space-around">
        <WrapItem>
          <Link
            href="https://discord.gg/kwGcNTvaQG"
            style={{ textDecoration: "none" }}
          >
            <CustomButton icon={<FaDiscord />}>JOIN OUR DISCORD</CustomButton>
          </Link>
        </WrapItem>
      </Wrap>
    </Flex>
  );
}

export default Opening;
