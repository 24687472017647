import { Heading, Link, Wrap, WrapItem } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import GuideItem from "../components/GuideItem";
import Loading from "../components/Loading";

export default function GameGuide() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/content/items/guide?sort=%7Bdate%3A+-1%7D`).then((response) => {
      setNewsData(response.data);
      setIsLoading(false);
    });
  }, []);
  return (
    <Wrap
      w={"full"}
      align={"center"}
      marginTop={50}
      direction={"column"}
      minH="calc(100vh - 400px)"
    >
      <Heading>GAME GUIDE</Heading>

      {isLoading ? <Loading /> : null}
      <Wrap
        templateColumns="repeat(3, 3fr)"
        spacing={10}
        zIndex={999}
        marginTop={50}
        justify={"center"}
        p={10}
      >
        {newsData.map((data) => {
          const link = `/guide/${data._id}`;
          return (
            <WrapItem key={data._id}>
              <Link href={link} style={{ textDecoration: "none" }}>
                <GuideItem
                  title={data.title}
                  image={`https://api.eco-iris.com/storage/uploads/${data.image.path}`}
                  date={data.date}
                  type={data.type}
                />
              </Link>
            </WrapItem>
          );
        })}
      </Wrap>
    </Wrap>
  );
}
